import React, { useEffect, useState } from 'react';
import { ReactSpreadsheetImport } from 'sheets-react-import';
// import logo from './logo.svg';
import './App.css';

// const fields = [
//   {
//     label: 'Name',
//     key: 'name',
//     alternateMatches: ['first name', 'first'],
//     fieldType: {
//       type: 'input',
//     },
//     example: 'Steph',
//     validations: [
//       {
//         rule: 'required',
//         errorMessage: 'Name is required',
//       },
//     ],
//   },
//   {
//     label: 'Surname',
//     key: 'surname',
//     alternateMatches: ['second name', 'last name', 'last'],
//     fieldType: {
//       type: 'input',
//     },
//     example: 'McDonald',
//     validations: [
//       {
//         rule: 'unique',
//         errorMessage: 'Last name must be unique',
//         level: 'info',
//       },
//     ],
//     description: 'Family / Last name',
//   },
//   {
//     label: 'Age',
//     key: 'age',
//     alternateMatches: ['years'],
//     fieldType: {
//       type: 'input',
//     },
//     example: '23',
//     validations: [
//       {
//         rule: 'regex',
//         value: '^\\d+$',
//         errorMessage: 'Age must be a number',
//         level: 'warning',
//       },
//     ],
//   },
//   {
//     label: 'Team',
//     key: 'team',
//     alternateMatches: ['department'],
//     fieldType: {
//       type: 'select',
//       options: [
//         { label: 'Team One', value: 'one' },
//         { label: 'Team Two', value: 'two' },
//       ],
//     },
//     example: 'Team one',
//     validations: [
//       {
//         rule: 'required',
//         errorMessage: 'Team is required',
//       },
//     ],
//   },
//   {
//     label: 'Is manager',
//     key: 'is_manager',
//     alternateMatches: ['manages'],
//     fieldType: {
//       type: 'checkbox',
//       booleanMatches: {},
//     },
//     example: 'true',
//   },
// ];

const onSubmission = async (data: any, file: any) => {
  // const sheetId = await serverFunctions.getSpreadsheetId();
  console.log(data)
  console.log(file)
};

const closeModal = () => {
  console.log('closed modal');
};

function App() {
  const [fields, setFields] = useState([]);
  const [rowHook, setRowHook] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [sheetId, setSheetId] = useState('');
  const [maxRecords, setMaxRecords] = useState(1000000000);
  const [customTheme, setCustomTheme] = useState({});
  const [sampleSheet, setSampleSheet] = useState('');

  useEffect(() => {
    const params: any = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop as string),
    });
    setSheetId(params?.spreadSheetId);
    const requestOptions = {
      method: 'GET',
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}/template/sheet?sheetId=${params?.spreadSheetId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log('old', result);
        setFields(result.fields);
        setRowHook(eval("(" + result.rowHook + ")"));
        setMaxRecords(result.maxRecords);
        setCustomTheme(result.customTheme);
        setSampleSheet(result.sampleSheet);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="">
      {!isLoading && <ReactSpreadsheetImport
        isOpen={true}
        onClose={closeModal}
        onSubmit={onSubmission}
        fields={fields}
        rowHook={rowHook}
        customTheme={customTheme}
        maxRecords={maxRecords}
        isNavigationEnabled={true}
        autoMapSelectValues={true}
        sheetId={sheetId}
        autoMapDistance={6}
        sampleSheet={sampleSheet}
        downloadSample={true}
        serverURL={process.env.REACT_APP_BACKEND_URL}
        sheetImport={false}
      />}
    </div>
  );
}

export default App;
